import data from './formatting.data.js';
import $t from './translate.ts';

let formatString = function(pValue) { /*(pValue, args)*/
    var args = Array.prototype.slice.call(arguments, 1);
    return pValue.replace(data.dataRegex.formatRegex, function (f, m) {
        return args[+m];
    });
}

function camelCase(pString, pUpperFirst, pKeepSpaces) {
    var vSplitted = pString.split(" ");
    pString = vSplitted.map(function (str) {
        return str[0].toUpperCase() + str.slice(1);
    }).join(pKeepSpaces ? " " : "");
    return (pUpperFirst ? pString : pString[0].toLowerCase() + pString.slice(1));
}

function unCamelCase(pString, pLowerFirst, pLowerRest) {
    var vSplitted = pString.split(/(?=[A-Z\xc6\xd8\xc5\d])/g);
    vSplitted = vSplitted.reduce(function (previousValue, currentValue) {
        if (previousValue.length && previousValue[previousValue.length - 1] === previousValue[previousValue.length - 1].toUpperCase()) {
            previousValue[previousValue.length - 1] += currentValue;
        } else {
            previousValue.push(currentValue);
        }
        return previousValue;
    }, []);
    pString = vSplitted.join(" ");
    if (pString.length == 0) { return ''; }
    if (pLowerRest) { pString = pString.toLowerCase(); }
    return (pLowerFirst ? pString : pString[0].toUpperCase() + pString.slice(1));
}

function unCamelCaseAndTranslate(pString){
    return $t(unCamelCase(pString));
}

export default {formatString, camelCase,unCamelCase, unCamelCaseAndTranslate} 


